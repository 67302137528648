import React from 'react';
import { Button, Loading } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface ButtonCustomProps {
  children?: React.ReactNode;
  onClick?: (e?: any) => void;
  type?: 'submit' | 'button';
  loading?: boolean;
  disabled?: boolean;
  variant?: 'outline' | 'transparent';
  modifier?: ('uppercase' | 'lowercase' | 'widthFit' | 'w150' | 'w255')[],
  className?: string;
  icon?: React.ReactNode,
}

const ButtonCustom: React.FC<ButtonCustomProps> = ({
  children, onClick,
  type,
  disabled,
  loading,
  variant,
  modifier,
  className,
  icon
}) => (
  <Button
    extendClasses={`${mapModifiers('a-button', variant, modifier)} ${className}`}
    type={type}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
    icon={icon}
    loadingIndicator={loading ? <Loading.CircleDashed color="#fff" width={24} /> : undefined}
  >
    {children}
  </Button>
);

export default ButtonCustom;
