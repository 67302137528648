import React, { forwardRef, useRef } from 'react';
import { Input } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

type Variant = 'form-otp';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  className?: string;
  error?: any;
  variant?: Variant;
  handleClickSend?: () => void;
  handleClickCopy?: () => void;
  textButton?: string;
  isLoading?: boolean;
}

const InputRef: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    className, variant, error,
    textButton,
    isLoading,
    handleClickSend,
    handleClickCopy,
    onKeyDown,
    ...props
  },
  ref,
) => {
  const inputRef = useRef(null);
  return (
    <div
      className={mapModifiers('a-inputCustom', props.type === 'hidden' ? 'hidden' : '', props.disabled ? 'disabled' : '')}
      ref={inputRef}
    >
      <Input
        ref={ref}
        extendClasses={`${mapModifiers('a-inputCustom_input', error && 'error', variant)} ${className}`}
        status={error ? 'error' : undefined}
        bordered
        {...props}
      />
      {error && (
        <div className="m-pulldown_error">
          <Icon iconName="warning" size="16" />
          <span style={{ fontSize: 13, color: '#dc1f18', marginLeft: '4px' }}>{error}</span>
        </div>
      )}
    </div>
  );
};

const InputCustom = forwardRef(InputRef);

export default InputCustom;
