import { SpecializesDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getSpecializesService = async (params: RequestParamsTypes)
  : Promise<APIPaginationResponse<SpecializesDataTypes[]>> => {
  const response = await axiosInstance.get('specializes', { params });
  return response.data;
};

export const placeholder = '';
