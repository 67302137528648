import React, { useEffect } from 'react';
import Modal from 'react-modal';

import ButtonCustom from 'components/atoms/Button';
import mapModifiers from 'utils/functions';

export type VariantModal = 'default';

interface ModalProps {
  isOpen: boolean;
  variant?: VariantModal;
  children?: React.ReactNode;
  modifiers?: string[];
  noButton?: boolean;
  isSmall?: boolean
  okButton?: {
    text?: string;
    onClick: () => void;
  };
  cancelButton?: {
    text?: string;
    onClick: () => void;
  } | 'hide';
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  variant,
  children,
  modifiers,
  noButton,
  isSmall,
  okButton,
  cancelButton = {
    text: 'Cancel',
    onClick: () => { }
  }
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className={mapModifiers('o-modal', variant)}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      portalClassName={mapModifiers('o-modal_portal', isOpen && 'open')}
      overlayClassName={mapModifiers('o-modal_overlay', variant)}
    >
      <div className={mapModifiers('o-modal_main', isSmall && 'small')}>
        <div className={mapModifiers('o-modal_wrapper', modifiers)}>
          <div className="o-modal_body">
            {children}
          </div>
          {
            !noButton && (okButton || !(typeof cancelButton === 'string' && cancelButton === 'hide')) && (
              <div className="o-modal_button">
                {
                  okButton && (
                    <ButtonCustom
                      type="button"
                      onClick={okButton.onClick}
                      modifier={['w150']}
                    >
                      {okButton.text}
                    </ButtonCustom>
                  )
                }
                {
                  !(typeof cancelButton === 'string' && cancelButton === 'hide') && (
                    <ButtonCustom
                      type="button"
                      onClick={cancelButton.onClick}
                      className="btn-gradient-primary"
                      modifier={['w150']}
                    >
                      {cancelButton.text || 'Cancel'}
                    </ButtonCustom>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
