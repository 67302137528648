export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'AZ_CONSENT_FORM_ACCESS_TOKEN',
  REFRESH_TOKEN: 'AZ_CONSENT_FORM_REFRESH_TOKEN',
  SOCIAL_PKCE_UUID: 'AZ_CONSENT_FORM_SOCIAL_PKCE_UUID',
  SOCIAL_PKCE_STATE: 'AZ_CONSENT_FORM_SOCIAL_PKCE_STATE',
  UTM_SOURCE: 'AZ_CONSENT_FORM_UTM_SOURCE',
};

export const SOCIAL_LOGIN_HOST = {
  ZALO: 'https://oauth.zaloapp.com/v4/permission',
};
