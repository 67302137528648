import React, { Suspense, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Route, Routes
} from 'react-router-dom';
import { Loading } from 'tfc-components';
import './App.scss';

import useInitializeRender from 'hooks/useInitializeRender';
import Home from 'pages/Home';
import { store } from 'store';
import { DEFAULT_QUERY_OPTION } from 'utils/constants';

const App: React.FC = () => {
  useInitializeRender();

  return (
    <Suspense fallback={<Loading.CircleDashed color="#fff" width={60} />}>
      <Routes>
        <Route>
          <Route
            path="/"
            element={<Home />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

const AppProvider: React.FC = () => {
  const queryClient = useRef(new QueryClient({
    defaultOptions: {
      queries: DEFAULT_QUERY_OPTION,
    },
  }));

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient.current}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
