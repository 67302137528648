import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  rightArrow: 'rightArrow',
  leftArrow: 'leftArrow',
  arrowDownBound: 'arrowDownBound',
  warning: 'warning',
};

export type IconName = keyof typeof iconList;

export type IconSize = '16' | '24' | '32' | 'full';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ iconName, size, className }) => (
  <i className={`${mapModifiers('a-icon', iconName, size)} ${className || ''}`} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
