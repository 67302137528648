import {
 CustomerParamsTypes, CustomerResponseTypes, SendOTPResponseTypes, SendOTPToCustomerParamsTypes
} from './types';

import axiosInstance from 'services/common/instance';

export const postCustomerService = async (params: CustomerParamsTypes): Promise<void> => {
  const response = await axiosInstance.post('customers/store-for-web', params);
  return response.data;
};

export const updateCustomerService = async (params: CustomerParamsTypes): Promise<void> => {
  const response = await axiosInstance.put(`customers/${params.phone}/for-web`, params);
  return response.data;
};

export const sendOTPCustomerService = async (params: SendOTPToCustomerParamsTypes)
  : Promise<SendOTPResponseTypes> => {
  const response = await axiosInstance.post('customers/send-otp', params);
  return response.data.data;
};

export const getInfoCustomerByPhoneService = async (params: SendOTPToCustomerParamsTypes)
  : Promise<CustomerResponseTypes> => {
  const response = await axiosInstance.get(`customers/${params.phone}/for-web`, { params: { grecaptcha_token: params.grecaptcha_token } });
  return response.data.data;
};
