import { useEffect } from 'react';

import { useAppDispatch } from 'store/hooks';
import {
  getSystemAction
} from 'store/system';

const useInitializeRender = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSystemAction());
  }, []);
};

export default useInitializeRender;
