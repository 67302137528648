import React, { forwardRef, useRef } from 'react';

import mapModifiers from 'utils/functions';

interface CheckboxCustomProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: any;
  children?: React.ReactNode;
}

const CheckboxCustomRef: React.ForwardRefRenderFunction<HTMLInputElement, CheckboxCustomProps> = (
  {
    children,
    error,
    id,
    className,
    ...props
  },
  ref,
) => {
  const inputRef = useRef(null);
  return (
    <>
      <div className={className}>
        <label className="a-checkbox" ref={inputRef} htmlFor={id}>
          <span className="a-checkbox_radio">
            <input {...props} ref={ref} type="checkbox" className="a-checkbox_radio-input" id={id} />
            <span className={mapModifiers('a-checkbox_radio-inner', props.checked ? 'checked' : '')}>
              {
                props.checked ? (
                  <span className="a-checkbox_radio-inner-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 11" fill="none">
                      <path d="M1.5 5.5L5 9L12.5 1.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                ) : null
              }
            </span>
          </span>
          <span>
            {children}
          </span>
        </label>
      </div>
      {error && <div style={{ fontSize: 13, color: 'red' }}>{error.message}</div>}
    </>

  );
};
const CheckboxCustom = forwardRef(CheckboxCustomRef);

export default CheckboxCustom;
