/* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  useEffect, useMemo,
  useRef
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Label, Typography } from 'tfc-components';
import * as yup from 'yup';

import error from 'assets/images/error.png';
// import qrAZ from 'assets/images/qr_az.png';
import success from 'assets/images/success.png';
import ButtonCustom from 'components/atoms/Button';
import CheckboxCustom from 'components/atoms/Checkbox';
import InputCustom from 'components/atoms/Input';
import Loading from 'components/atoms/Loading';
import Pulldown from 'components/molecules/Pulldown';
import CustomModal from 'components/organisms/Modal';
// import ModalOtp from 'components/organisms/ModalOtp';
import useDebounce from 'hooks/useDebounce';
import {
  getInfoCustomerByPhoneService, postCustomerService,
  updateCustomerService
} from 'services/Customer';
import { CustomerParamsTypes, SendOTPToCustomerParamsTypes, SourceTypes } from 'services/Customer/types';
import { getProfessionsService } from 'services/Professions';
import { getSpecializesService } from 'services/Specializes';
import { setLocalStorage } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE } from 'utils/constants';

type FormValues = {
  lastName: string;
  firstName: string;
  phone: string;
  job: OptionType;
  specialization: OptionType;
  workplace: string;
  nameHospital: string;
  email: string | undefined;
  agree: boolean;
  healthcareProfessional: boolean;
};

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const customerInfo = yup.object().shape({
  lastName: yup.string().required('Vui lòng cung cấp thông tin'),
  firstName: yup.string().required('Vui lòng cung cấp thông tin'),
  phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Vui lòng cung cấp thông tin'),
  job: yup.object().shape({
    value: yup.string().required('Vui lòng cung cấp thông tin'),
    label: yup.string().required('Vui lòng cung cấp thông tin'),
  }).test('job', 'Vui lòng cung cấp thông tin', (value) => {
    if (value === undefined || value?.value === undefined) return false;
    return true;
  }).required('Vui lòng cung cấp thông tin'),
  specialization: yup.object().shape({
    value: yup.string().required('Vui lòng cung cấp thông tin'),
    label: yup.string().required('Vui lòng cung cấp thông tin'),
  }).test('specialization', 'Vui lòng cung cấp thông tin', (value) => {
    if (value === undefined || value?.value === undefined) return false;
    return true;
  }).required('Vui lòng cung cấp thông tin'),
  nameHospital: yup.string().required('Vui lòng cung cấp thông tin'),
  workplace: yup.string().required('Vui lòng cung cấp thông tin'),
  email: yup.string().email('Email chưa hợp lệ'),
  agree: yup.bool().default(true).oneOf([true], 'Vui lòng đồng ý để có thể đăng ký thông tin.'),
  healthcareProfessional: yup.bool().default(false).oneOf([true], 'Vui lòng đồng ý để có thể đăng ký thông tin.'),
});

interface FormInformationProps { }

const DEFAULT_VALUES = {
  lastName: '',
  firstName: '',
  phone: '',
  job: {
    label: undefined,
    value: undefined
  },
  specialization: {
    label: undefined,
    value: undefined
  },
  workplace: '',
  email: '',
  nameHospital: '',
  agree: true,
  healthcareProfessional: true
};

const FormInformation: React.FC<FormInformationProps> = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const googleRecaptchaSiteKey = useAppSelector((state) => state.systems
    .system?.googleRecaptchaSiteKey);

  const { search } = useLocation();
  const utmParams = new URLSearchParams(search);
  const utmSource = utmParams.get('utm_source') as SourceTypes;

  const [isShowPopup, setIsShowPopup] = React.useState<{
    isShowPopup: boolean;
    type?: 'warning' | 'success' | 'multipleRequest';
    message: string;
  }>({
    isShowPopup: false,
    message: ''
  });

  // const [errorOtp, setErrorOtp] = React.useState<string>('');

  // const [isShowPopupVerify, setIsShowPopupVerify] = React.useState<boolean>(false);

  const method = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
    resolver: yupResolver(customerInfo),
  });

  const phoneWatch = method.watch('phone');

  const {
    data: infoUser,
    mutate: getInfoCustomerByPhoneMutate,
    isLoading: isLoadingInfoCustomerByPhone
  } = useMutation(
    'getInfoCustomerByPhone',
    async (data: SendOTPToCustomerParamsTypes) => getInfoCustomerByPhoneService(data),
    {
      onSuccess: (data) => {
        if (data) {
          method.reset({
            lastName: data.last_name,
            firstName: data.first_name,
            phone: data.phone,
            job: {
              label: data.profession?.name || '',
              value: data.profession?.id?.toString() || ''
            },
            specialization: {
              label: data.specialize?.name || '',
              value: data.specialize?.id?.toString() || ''
            },
            workplace: data.department || '',
            nameHospital: data.hospital_name || '',
            agree: data.is_read_privacy_policy,
            healthcareProfessional: data.is_healthcare_professional,
            email: data.email || '',
          });
        }
      },
      onError: () => {
        method.reset({
          lastName: '',
          firstName: '',
          phone: phoneWatch,
          job: {
            label: '',
            value: undefined
          },
          specialization: {
            label: '',
            value: undefined
          },
          workplace: '',
          nameHospital: '',
          agree: true,
          healthcareProfessional: true,
          email: '',
        });
      }
    }
  );

  useDebounce(async () => {
    if (phoneWatch && phoneWatch.length === 10) {
      if (!recaptchaRef.current) return;
      try {
        const token = await recaptchaRef.current.executeAsync();
        if (!token) return;
        getInfoCustomerByPhoneMutate({
          phone: phoneWatch,
          grecaptcha_token: token
        });
      } catch (e) {
        // Empty
        console.log('e getInfoCustomerByPhone:', e);
      } finally {
        recaptchaRef.current?.reset();
      }
    }
  }, 1000, [phoneWatch]);

  useEffect(() => {
    if (utmSource) {
      setLocalStorage(LOCAL_STORAGE.UTM_SOURCE, utmSource);
    }
  }, [utmSource]);

  const {
    data: professionsData,
    isLoading: isLoadingProfessions,
    isFetching: isFetchingProfessions,
    fetchNextPage: fetchNextPageProfession,
    hasNextPage: hasNextPageProfessions
  } = useInfiniteQuery(
    ['getProfessions'],
    ({ pageParam = 1 }) => getProfessionsService({
      page: pageParam,
    }),
    {
      getNextPageParam: (lastPage) => (lastPage.meta.page < lastPage.meta.totalPages
        ? lastPage.meta.page + 1 : undefined),
    }
  );

  const professions: OptionType[] = useMemo(() => {
    if (professionsData) {
      return professionsData.pages.reduce((acc, cur) => {
        const newAcc = [...acc, ...cur.data.map((item) => ({
          label: item.name,
          value: item.id?.toString() || '',
        } as OptionType))];
        return newAcc;
      }, [] as OptionType[]);
    }
    return [];
  }, [professionsData]);

  const {
    data: specializesData,
    isLoading: isLoadingSpecializes,
    isFetching: isFetchingSpecializes,
    fetchNextPage: fetchNextPageSpecializes,
    hasNextPage: hasNextPageSpecializes
  } = useInfiniteQuery(
    ['getSpecializes'],
    ({ pageParam = 1 }) => getSpecializesService({
      page: pageParam,
    }),
    {
      getNextPageParam: (lastPage) => (lastPage.meta.page < lastPage.meta.totalPages
        ? lastPage.meta.page + 1 : undefined),
    }
  );

  const specializes: OptionType[] = useMemo(() => {
    if (specializesData) {
      return specializesData.pages.reduce((acc, cur) => {
        const newAcc = [...acc, ...cur.data.map((item) => ({
          label: item.name,
          value: item.id?.toString() || '',
        } as OptionType))];
        return newAcc;
      }, [] as OptionType[]);
    }
    return [];
  }, [specializesData]);

  const { mutate: createCustomerInfo, isLoading: isLoadingCreateCustomer } = useMutation(
    'postCustomerInfo',
    async (data: CustomerParamsTypes) => postCustomerService(data),
    {
      onSuccess: () => {
        method.reset(DEFAULT_VALUES);
        // setIsShowPopupVerify(false);
        setIsShowPopup({
          isShowPopup: true,
          type: 'success',
          message: 'Thông tin của bạn đã được đăng ký thành công!'
        });
      },
      onError: (errorRes: any) => {
        // if (Array.isArray(errorRes) && errorRes.length > 0) {
        //   if (errorRes[0]?.code === 'INVALID_OTP') {
        //     setErrorOtp('Mã OTP không hợp lệ!');
        //     return;
        //   }
        //   if (errorRes[0]?.code === 'EXPIRED_OTP') {
        //     setErrorOtp('Mã OTP đã hết hạn!');
        //     return;
        //   }
        // }
        // setIsShowPopupVerify(false);
        setIsShowPopup({
          isShowPopup: true,
          type: 'warning',
          message: 'Hệ thống đang được bảo trì, vui lòng thử lại sau!'
        });
      }
    }
  );

  const { mutate: updateCustomerInfo, isLoading: isLoadingUpdateCustomer } = useMutation(
    'postCustomerInfo',
    async (data: CustomerParamsTypes) => updateCustomerService(data),
    {
      onSuccess: () => {
        method.reset(DEFAULT_VALUES);
        // setIsShowPopupVerify(false);
        setIsShowPopup({
          isShowPopup: true,
          type: 'success',
          message: 'Thông tin của bạn đã được cập nhật thành công!'
        });
      },
      onError: (errorRes: any) => {
        // if (Array.isArray(errorRes) && errorRes.length > 0) {
        //   if (errorRes[0]?.code === 'INVALID_OTP') {
        //     setErrorOtp('Mã OTP không hợp lệ!');
        //     return;
        //   }
        //   if (errorRes[0]?.code === 'EXPIRED_OTP') {
        //     setErrorOtp('Mã OTP đã hết hạn!');
        //     return;
        //   }
        // }
        // setIsShowPopupVerify(false);
        if (errorRes?.response?.data?.errors && errorRes?.response?.data?.errors.length > 0) {
          setIsShowPopup({
            isShowPopup: true,
            type: 'warning',
            message: `${errorRes?.response?.data?.errors[0]?.title}`
          });
          return;
        }
        setIsShowPopup({
          isShowPopup: true,
          type: 'warning',
          message: 'Hệ thống đang được bảo trì, vui lòng thử lại sau!'
        });
      }
    }
  );

  // const {
  //   data: sendOTPData,
  //   mutate: sentOtpCustomerInfo,
  //   isLoading: isLoadingSendOtp
  // } = useMutation(
  //   'sendOTP',
  //   async (data: SendOTPToCustomerParamsTypes
  //     & { isResendOtp?: boolean }) => sendOTPCustomerService(data),
  //   {
  //     onSuccess: (_, variables) => {
  //       if (!variables.isResendOtp) {
  //         setIsShowPopupVerify(true);
  //         setErrorOtp('');
  //       }
  //     },
  //     onError: (errorRes: any) => {
  //       if (errorRes?.errors) {
  //         setIsShowPopup({
  //           isShowPopup: true,
  //           type: 'warning',
  //           message: 'Gửi mã OTP không thành công! Vui lòng thử lại sau!'
  //         });
  //         return;
  //       }
  //       if (errorRes?.response?.status === 429) {
  //         setIsShowPopup({
  //           isShowPopup: true,
  //           type: 'multipleRequest',
  //           message: 'Bạn đã yêu cầu OTP quá số lần cho phép. Vui lòng thử lại sau 1 phút'
  //         });
  //         return;
  //       }
  //       setIsShowPopup({
  //         isShowPopup: true,
  //         type: 'warning',
  //         message: 'Hệ thống đang được bảo trì, vui lòng thử lại sau!'
  //       });
  //     }
  //   }
  // );

  const onHandleSubmitUser = async (data: FormValues) => {
    // const data = method.getValues();
    if (!recaptchaRef.current) return;

    try {
      const token = await recaptchaRef.current.executeAsync();
      if (!token) return;

      const paramsData = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone.replace(/^84/, '0'),
        profession_id: Number(data.job.value),
        specialized_id: Number(data.specialization.value),
        department: data.workplace,
        hospital_name: data.nameHospital,
        is_read_privacy_policy: data.agree,
        is_healthcare_professional: data.healthcareProfessional,
        grecaptcha_token: token,
        source: utmSource || 'web',
        // otp: otpCode,
        ...(data.email && { email: data.email })
      };
      if (infoUser && infoUser.phone) {
        updateCustomerInfo(paramsData);
      } else {
        createCustomerInfo(paramsData);
      }
    } catch (e) {
      // Empty
    } finally {
      recaptchaRef.current?.reset();
    }
  };

  // const onHandleSendOTP = async (isResendOtp?: boolean) => {
  //   if (!recaptchaRef.current) return;
  //   try {
  //     const token = await recaptchaRef.current.executeAsync();
  //     if (!token) return;
  //     sentOtpCustomerInfo({
  //       phone: method.watch('phone'),
  //       grecaptcha_token: token,
  //       isResendOtp
  //     });
  //   } catch (e) {
  //     console.log('e sendOtpForm:', e);
  //     // Empty
  //   } finally {
  //     recaptchaRef.current?.reset();
  //   }
  // };

  // const onHandleCheckInfo = async (data: FormValues) => {
  //   onHandleSendOTP();
  // };

  // const onHandleReSendOtp = async () => {
  //   onHandleSendOTP(true);
  // };

  return (
    <div className="t-formInformation">
      {
        isLoadingInfoCustomerByPhone && (
          <Loading isShow variant="fullScreen" />
        )
      }
      <Container>
        <FormProvider {...method}>
          <form className="t-formInformation_form" noValidate onSubmit={method.handleSubmit(onHandleSubmitUser)}>
            <Typography.Heading
              extendClasses="t-formInformation_heading"
            >
              ĐĂNG KÝ THÔNG TIN NHÂN VIÊN Y TẾ
            </Typography.Heading>
            <Row>
              <Col lg={6}>
                <Label extendClasses="t-formInformation_label">
                  Số điện thoại
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </Label>
                <Controller
                  name="phone"
                  render={({ field, fieldState }) => (
                    <InputCustom
                      {...field}
                      placeholder="Số điện thoại"
                      required
                      onChange={(e) => {
                        field.onChange(e.target.value.replace(/[^0-9]/g, ''));
                      }}
                      value={field.value}
                      error={fieldState.error?.message}
                      pattern="[0-9]*"
                      maxLength={10}
                    />
                  )}
                />
              </Col>
              <Col lg={6}>
                <Label extendClasses="t-formInformation_label">
                  Họ và tên đệm
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </Label>
                <Controller
                  name="lastName"
                  render={({ field, fieldState }) => (
                    <InputCustom
                      {...field}
                      placeholder="Họ và tên đệm"
                      required
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Label extendClasses="t-formInformation_label u-mt-12">
                  Tên
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </Label>
                <Controller
                  name="firstName"
                  render={({ field, fieldState }) => (
                    <InputCustom
                      {...field}
                      placeholder="Tên"
                      required
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Col>
              <Col lg={6}>
                <Label extendClasses="t-formInformation_label u-mt-12">
                  Nghề nghiệp
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </Label>
                <Controller
                  name="job"
                  render={({ field, fieldState }) => (
                    <Pulldown
                      {...field}
                      options={professions}
                      placeholder="Chọn nghề nghiệp"
                      error={fieldState.error?.message}
                      loading={isLoadingProfessions || isFetchingProfessions}
                      isDisabled={isLoadingProfessions || isFetchingProfessions}
                      isBottom={hasNextPageProfessions}
                      onScrollLatest={() => fetchNextPageProfession()}
                      handleChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Label extendClasses="t-formInformation_label u-mt-12">
                  Chuyên môn
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </Label>
                <Controller
                  name="specialization"
                  render={({ field, fieldState }) => (
                    <Pulldown
                      {...field}
                      options={specializes}
                      value={field.value}
                      placeholder="Chọn chuyên môn"
                      error={fieldState.error?.message}
                      loading={isLoadingSpecializes || isFetchingSpecializes}
                      isDisabled={isLoadingSpecializes || isFetchingSpecializes}
                      isBottom={hasNextPageSpecializes}
                      onScrollLatest={() => fetchNextPageSpecializes()}
                      handleChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </Col>

              <Col lg={6}>
                <Label extendClasses="t-formInformation_label u-mt-12">
                  Khoa phòng bệnh viện/nhà thuốc - mã KH/nơi làm việc
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </Label>
                <Controller
                  name="workplace"
                  render={({ field, fieldState }) => (
                    <InputCustom
                      {...field}
                      placeholder="Khoa phòng bệnh viện/nhà thuốc - mã KH/nơi làm việc"
                      required
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Label extendClasses="t-formInformation_label u-mt-12">
                  Tên bệnh viện/phòng khám/Tên nhà thuốc
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </Label>
                <Controller
                  name="nameHospital"
                  render={({ field, fieldState }) => (
                    <InputCustom
                      {...field}
                      placeholder="Tên bệnh viện/phòng khám/Tên nhà thuốc"
                      className="page_input"
                      required
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Col>

              <Col lg={6}>
                <Label extendClasses="t-formInformation_label u-mt-12">
                  Email
                </Label>
                <Controller
                  name="email"
                  render={({ field, fieldState }) => (
                    <InputCustom
                      {...field}
                      placeholder="Email"
                      className="page_input"
                      required
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            <a
              target="_blank"
              href="https://www.azprivacy.astrazeneca.com/asia-pacific/vietnam/vn/privacy-notices.html"
              className="t-formInformation_link"
              rel="noreferrer"
            >
              <span>Vui lòng tham khảo thông báo về quyền riêng tư</span>
            </a>
            <Controller
              name="agree"
              render={({ field, fieldState }) => (
                <CheckboxCustom
                  {...field}
                  value={field.value}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  style={{ marginTop: 16 }}
                  error={fieldState.error}
                >
                  Có, tôi đã đọc và đồng ý với những điều trên
                </CheckboxCustom>
              )}
            />
            <Controller
              name="healthcareProfessional"
              render={({ field, fieldState }) => (
                <CheckboxCustom
                  {...field}
                  className="u-mt-12"
                  value={field.value}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  style={{ marginTop: 16 }}
                  error={fieldState.error}
                >
                  Có, tôi là cán bộ y tế
                </CheckboxCustom>
              )}
            />
            <div className="t-formInformation_button">
              <ButtonCustom
                type="submit"
                disabled={isLoadingUpdateCustomer}
                loading={isLoadingUpdateCustomer}
              >
                Đăng ký
              </ButtonCustom>
            </div>
            {
              googleRecaptchaSiteKey && (
                <ReCAPTCHA
                  sitekey={googleRecaptchaSiteKey}
                  size="invisible"
                  ref={recaptchaRef}
                />
              )
            }
          </form>
        </FormProvider>
        <div className="t-formInformation_footer">
          <a
            target="_blank"
            href="https://www.astrazeneca.com/country-sites/vietnam/legal-notice-and-terms-of-use.html"
            className="t-formInformation_footer_link"
            rel="noreferrer"
          >
            <span>Thông báo pháp lý và điều khoản</span>
          </a>
          <span className="t-formInformation_footer_divider" />
          <a
            target="_blank"
            href="https://www.astrazeneca.vn/lien-he"
            className="t-formInformation_footer_link"
            rel="noreferrer"
          >
            <span>Liên hệ</span>
          </a>
        </div>
        <CustomModal
          isOpen={isShowPopup.isShowPopup}
          isSmall
          cancelButton={{
            text: 'Đóng',
            onClick: () => {
              setIsShowPopup({ ...isShowPopup, isShowPopup: false });
            }
          }}
        >
          {
            isShowPopup.type !== 'multipleRequest' && (
              <div className="t-formInformation_logo">
                <img src={isShowPopup.type === 'success' ? success : error} alt="Icon" width={60} height={60} />
              </div>
            )
          }
          <div>
            <Typography.Heading
              extendClasses="t-formInformation_heading"
            >
              {isShowPopup.message}
            </Typography.Heading>
          </div>
          {/* {
            isShowPopup.type === 'success' && (
              <div className="t-formInformation_popup">
                <div className="t-formInformation_popup-desktop">
                  <div className="t-formInformation_qr">
                    <img src={qrAZ} alt="QR AZ" width={200} height={200} />
                  </div>
                  <Typography.Text extendClasses="t-formInformation_textRedirect">
                    Quét mã QR để truy cập trang chính thức của AstraZeneca Việt Nam
                  </Typography.Text>
                </div>
                <div className="t-formInformation_popup-mobile">
                  <Typography.Text extendClasses="t-formInformation_textRedirect">
                    Nhấn vào
                    {' '}
                    <a href="https://zalo.me/2517282459797498674" target="_blank" className="t-formInformation_linkRedirect" rel="noreferrer">
                      link
                    </a>
                    {' '}
                    đây để truy cập trang chính thức
                    của AstraZeneca Việt Nam
                  </Typography.Text>
                </div>
              </div>
            )
          } */}
        </CustomModal>
        {/* <ModalOtp
          isShow={isShowPopupVerify}
          onSendOtp={onHandleSubmitUser}
          phone={method.watch('phone')}
          onResendOtp={onHandleReSendOtp}
          isLoading={isLoadingSendOtp || isLoadingCreateCustomer}
          timeOTP={{
            retryIn: sendOTPData?.retryIn || 0,
            expiresIn: sendOTPData?.expiresIn || 0
          }}
          errorOtp={errorOtp}
        /> */}
      </Container>
    </div>
  );
};

export default FormInformation;
