import { ProfessionsDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getProfessionsService = async (params: RequestParamsTypes)
  : Promise<APIPaginationResponse<ProfessionsDataTypes[]>> => {
  const response = await axiosInstance.get('professions', { params });
  return response.data;
};

export const placeholder = '';
