/* eslint-disable */
import React, { forwardRef } from 'react';
import ReactSelect, { components } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

interface PulldownProps {
  name: string;
  options: OptionType[];
  placeholder?: string;
  error?: string;
  value?: OptionType;
  multiple?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
  isBottom?: boolean;
  onScrollLatest?: () => void;
  handleChange?: (option: OptionType) => void;
  onMenuOpen?: () => void;
  className?: string;
}

const Option: React.FC = (props: any) => {
  const { children } = props;

  return (
    <components.Option {...props}>
      <div className="m-pulldown__option__item">
        {children}
      </div>
    </components.Option>
  );
};

const PulldownRef: React.ForwardRefRenderFunction<Select, PulldownProps> = (
  {
    name,
    options,
    placeholder,
    error,
    multiple,
    loading,
    isDisabled,
    isBottom,
    value,
    className,
    onScrollLatest,
    handleChange,
    onMenuOpen
  },
  ref,
) => (
  <div className="m-pulldown">
    <ReactSelect
      isDisabled={isDisabled}
      ref={ref}
      name={name}
      className={`${mapModifiers('m-pulldown', !!error && 'error', isDisabled && 'disabled')} ${className}`}
      classNamePrefix="m-pulldown"
      options={options}
      value={value?.value ? value : null}
      blurInputOnSelect
      isSearchable={false}
      menuPlacement={isBottom ? 'top' : 'auto'}
      placeholder={placeholder}
      isMulti={multiple}
      onChange={(v: any) => handleChange && handleChange(v)}
      onMenuOpen={onMenuOpen}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
        },
        spacing: {
          ...theme.spacing,
          controlHeight: 48,
        }
      })}
      isLoading={loading}
      styles={{
        control: (base: any) => ({
          ...base,
          border: '0.7px solid #b9bdc1',
          background: 'white',
          width: '100%',
        }),
        option: (base: any) => ({
          ...base,
        }),
        placeholder: (base: any) => ({
          ...base,
          color: '#b9bdc1',
          fontSize: '16px',
          fontWeight: 700,
          fontFamily: 'unset'
        }),
        singleValue: (base: any) => ({
          ...base,
          color: '#650035',
          fontSize: '16px',
          fontWeight: 700,
          fontFamily: 'unset'
        }),
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        DropdownIndicator: () => (<Icon iconName="arrowDownBound" size="24" />),
        Option,
      }}
      onMenuScrollToBottom={onScrollLatest}
    />
    {error && (
      <div className="m-pulldown_error">
        <Icon iconName="warning" size="16" />
        <span style={{ fontSize: 13, color: '#dc1f18', marginLeft: '4px' }}>{error}</span>
      </div>
    )}
  </div>
);

const Pulldown = forwardRef(PulldownRef);

export default Pulldown;
