import React from 'react';
import { Container } from 'react-bootstrap';

import logo from 'assets/images/az_logo.png';
import FormInformation from 'components/templates/FormInformation';

const Home: React.FC = () => (
  <div className="p-home">
    <Container fluid>
      <div className="p-home_logo">
        <img src={logo} alt="AZ" className="p-home_logo-img" />
      </div>
    </Container>
    <div className="p-home_form">
      <FormInformation />
    </div>
  </div>
);

export default Home;
